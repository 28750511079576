import { useTranslation } from 'react-i18next';

export const getHelpData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('index_help_1_title'),
      text: t('index_help_1_text'),
      number: '30%',
    },
    {
      title: t('index_help_2_title'),
      text: t('index_help_2_text'),
      number: '80%',
    },
    {
      title: t('index_help_3_title'),
      text: t('index_help_3_text'),
      number: '5x',
    },
  ];
};

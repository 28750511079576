import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import Heading, { HeadingVariant } from 'src/components/Heading';
import Content from 'components/Content';
import { device } from 'src/theme/breakpoints';
import List from 'components/List';

const Container = styled('div')`
  background-color: ${colors.black};
  color: ${colors.grey100};

  @media ${device.laptopSUP} {
    padding: 24px 0 32px;
  }
`;

const Wrapper = styled(Content)`
  display: grid;
  grid-template-columns: 1fr;

  @media ${device.tabletUP} {
    align-items: center;
    column-gap: 48px;
    grid-template-columns: 1fr 0.95fr;
  }

  @media ${device.desktop} {
    column-gap: 118px;
  }
`;

const Text = styled.p`
  margin: 16px 0;

  @media ${device.laptopUP} {
    margin: 32px 0;
  }
`;

const TextWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  & ul {
    margin: 16px 0;

    @media ${device.laptopUP} {
      margin: 32px 0;
      max-width: 520px;
    }
  }

  @media ${device.mobileDOWN} {
    margin-bottom: 32px;
  }
`;

const Image = styled.div`
  max-width: 656px;

  @media ${device.tablet} {
    max-width: 400px;
  }

  & img {
    height: auto;
    max-width: 100%;
  }
`;

interface BannerProps {
  children?: React.ReactNode;
  className?: string;
  image: React.ReactNode;
  list?: string[];
  text?: string;
  title: string;
}

const Banner: FC<BannerProps> = ({
  children,
  className,
  image,
  list,
  text,
  title,
}) => (
  <Container className={className}>
    <Wrapper>
      <TextWrapper>
        <Heading variant={HeadingVariant.ALFA}>{title}</Heading>
        {text && <Text>{text}</Text>}
        {list && <List items={list} />}
        {children && children}
      </TextWrapper>
      <Image>{image}</Image>
    </Wrapper>
  </Container>
);

export default Banner;

import { useTranslation } from 'react-i18next';

export const getSolutionsData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('index_solutions_1_title'),
      text: t('index_solutions_1_text'),
    },
    {
      title: t('index_solutions_2_title'),
      text: t('index_solutions_2_text'),
    },
    {
      title: t('index_solutions_3_title'),
      text: t('index_solutions_3_text'),
    },
    {
      title: t('index_solutions_4_title'),
      text: t('index_solutions_4_text'),
    },
    {
      title: t('index_solutions_5_title'),
      text: t('index_solutions_5_text'),
    },
    {
      title: t('index_solutions_6_title'),
      text: t('index_solutions_6_text'),
    },
  ];
};

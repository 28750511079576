import { useTranslation } from 'react-i18next';

export const getBenefitsData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('index_benefits_1_title'),
      text: t('index_benefits_1_text'),
      number: '5x',
    },
    {
      title: t('index_benefits_2_title'),
      text: t('index_benefits_2_text'),
      number: '15%',
    },
    {
      title: t('index_benefits_3_title'),
      text: t('index_benefits_3_text'),
      number: '60%',
    },
    {
      title: t('index_benefits_4_title'),
      text: t('index_benefits_4_text'),
      number: '10%',
    },
  ];
};

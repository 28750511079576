import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import { LINKS } from 'src/utils/constants';

export const getFeaturesData = () => {
  const { t } = useTranslation();
  return [
    {
      image: (
        <StaticImage
          src="../../assets/images/features/product-import.png"
          alt={t('index_features_1_title')}
          width={290}
          height={290}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('index_features_1_title'),
      children: <Trans i18nKey="index_features_1_text" />,
      imageOnLeft: false,
      linkText: t('common_learn_more'),
      linkUrl: LINKS.PRODUCT_INFORMATION_IMPORT,
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/features/product-cards.png"
          alt={t('index_features_2_title')}
          width={290}
          height={290}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('index_features_2_title'),
      children: <Trans i18nKey="index_features_2_text" />,
      imageOnLeft: true,
      linkText: t('common_learn_more'),
      linkUrl: LINKS.ORGANISED_PRODUCT_CONTENT,
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/features/product-information.png"
          alt={t('index_features_3_title')}
          width={290}
          height={290}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('index_features_3_title'),
      children: <Trans i18nKey="index_features_3_text" />,
      imageOnLeft: false,
      linkText: t('common_learn_more'),
      linkUrl: LINKS.EDIT_PRODUCT_INFORMATION,
    },
  ];
};
